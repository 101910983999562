import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/layout"
import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import "react-multi-carousel/lib/styles.css"
import axios from "axios"
import { Link } from "gatsby"
import { STATUS } from "../constants/common"
import AuthContext from "../contexts/AuthContext"
const ForgotPassword = ({ getSignupView }) => {
  const [Email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [EmailError, setEmailError] = useState("")
  const [passwordError, setpasswordError] = useState("")
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [ErrorMessage, setErrorMessage] = useState("")
  const { sendResetPasswordMail } = useContext(AuthContext)

  function validateEmail(email) {
    // Regular expression to check the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email format is valid
    if (!emailRegex.test(email)) {
      return false
    }

    return true
  }
  const loginSubmit = async e => {
    e.preventDefault()

    if (Email !== "") {
      setErrorMessage("")

      if (!validateEmail(Email)) {
        setEmailError("Please Enter Vaild Email Address")
      } else {
        setEmailError("")
      }

      if (validateEmail(Email)) {
        // axios
        //   .post(`https://gofloaters.firebaseapp.com/login`, {
        //     email: Email,
        //     password: password,
        //   })
        //   .then(res => {
        //     setSuccessMessage("show")
        //     setEmail("")
        //     setPassword("")
        //   })
        try {
          const response = await sendResetPasswordMail(Email)
          if (response.status === STATUS.FAILED) {
            setEmailError(response.msg)
            return
          }
          alert("An email has been sent to your account. Please check.")
        } catch {
          setEmailError("something went wrong, please try again")
        }
      }
    } else {
      setEmailError("Please enter vaild email address")
      setpasswordError("Please enter vaild password")
    }
  }
  return (
    <div
      itemscope
      itemtype="https://schema.org/LocalBusiness"
      className="container newspaceDetailContainer"
    >
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div
            style={{
              border: "1px solid #d3d3d3",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <div className="text-center">
              <h3>Forgot Password</h3>
            </div>
            <form>
              <div className="form-group">
                <label htmlFor="email">
                  Email Address *:{" "}
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {EmailError}
                  </span>
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={Email}
                  onChange={e => {
                    setEmail(e.currentTarget.value)
                    setErrorMessage("")
                  }}
                  required
                />
              </div>

              <div className="text-center">
                <button
                  onClick={loginSubmit}
                  style={{
                    backgroundColor: "#2798B5",
                    alignItems: "center",
                    border: "none",
                    borderRadius: 4,
                    color: "white",
                    padding: "4px 16px",
                  }}
                >
                  Reset Password
                </button>
              </div>
              <br></br>
              <div className="text-center">
                <button
                  onClick={getSignupView}
                  style={{
                    background: "white",
                    border: 0,
                    color: "#2798B5",
                  }}
                >
                  New User? Signup
                </button>
              </div>
              <br />
            </form>
          </div>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-12">
          <br />
          <br />
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
